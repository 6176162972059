@font-face {
	font-family : 'Playfair';
	src: url('/fonts/Playfair/PlayfairDisplay-Regular.otf');
	font-weight:normal;
	font-style:normal;
	font-display:swap;
}
@font-face {
	font-family : 'Playfair';
	src: url('/fonts/Playfair/PlayfairDisplay-Bold.otf');
	font-weight:bold;
	font-style:normal;
	font-display:swap;
}
@font-face {
	font-family : 'Playfair';
	src: url('/fonts/Playfair/PlayfairDisplay-Regular.otf');
	font-weight:300;
	font-style:normal;
	font-display:swap;
}
/* TODO: Used in one place */
@font-face {
	font-family : 'Playfair';
	src: url('/fonts/Playfair/PlayfairDisplay-Italic.otf');
	font-weight:normal;
	font-style:italic;
	font-display:swap;
}

@font-face {
	font-family : 'FaktPro';
	src: url('/fonts/FaktPro/Fakt_Normal/FaktPro-Normal.otf');
	font-weight:normal;
	font-style:normal;
	font-display:swap;
}
@font-face {
	font-family : 'FaktPro';
	src: url('/fonts/FaktPro/Fakt_Bold/FaktPro-Bold.otf');
	font-weight:bold;
	font-style:normal;
	font-display:swap;
}
